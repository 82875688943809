//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


// import QCalendar from '@quasar/quasar-ui-qcalendar';
import * as moment from 'moment-timezone';
import EventTable from "@/components/EventList/EventTable.vue";

export default {
  name: 'EventCalendar',
  components: {
    EventTable
  },
  data() {
    return {
      events: [],
      selectedDate:null,
      calendarDate:null,
      modalTimestamp:null,
      showDayDetailModal: false,
      dailyPreviewTableColumns: [
        {
          name: 'timeFrom',
          align: 'center',
          sortable: false,
          width:'100px'
        },
        {
          name: 'simpleTitle',
          align: 'left',
          sortable: false,
          width:'50%',

        },
        {
          name: 'simpleVenue',
          align: 'center',
          sortable: false,
        },
        {
          name: 'attendanceType',
          align: 'center',
          sortable: false,
        },
      ],
    };
  },
  props: [
    'eventList',
  ],
  emits: [
    'dateChanged',
    'onPreview,'
  ],
  methods: {

    isInPast(timestamp) {
      return timestamp && timestamp.past;
    },

    isGanapuja(event) {
      return event.tags.filter((t) => {
        return t.code === 'GANAPUJA'
      }).length;
    },

    getEvents(date) {
      const evs = this.events.filter((i) => {
        if (i.date.indexOf(date) > -1) return true; else return false;
      })
      return evs;
    },

    onPrev () {
      this.$refs.calendar.prev()
      this.emitDates();
    },

    onNext () {
      this.$refs.calendar.next()
      this.emitDates();
    },

    getFirstDate(date) {
      return moment(date).startOf('month').format('YYYY/MM/DD');
    },
    
    getLastDate(date) {
      return moment(date).endOf('month').format('YYYY/MM/DD');
    },

    emitDates() {
      this.$nextTick(() => {
        const firstDate = this.getFirstDate(this.selectedDate || new Date());
        const lastDate = this.getLastDate(this.selectedDate || new Date());
        this.$emit('dateChanged', { firstDate, lastDate });
      });
    },

    showDayDetail(timestamp) {
      this.modalTimestamp = timestamp;
      this.showDayDetailModal = true;
      
      const mdate = moment(timestamp.date);
      this.modalTimestamp.formatted = [mdate.format('dddd'), mdate.format('Do'), mdate.format('MMMM')];
    }

  },
  mounted() {
    this.events = this.eventList;
    this.calendarDate = new Date().toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' }); 
    this.emitDates();
  },
  destroyed() {
  },
  computed: {},
  watch: {
    eventList(newval) {
      this.events = newval
    },
    selectedDate(newval) {
      this.calendarDate = new Date(newval).toLocaleString(this.$i18n.locale, { month: 'long', year: 'numeric' }); 
    }
  },
};
